import React, { useEffect, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import Sidebar from "../widgets/Sidebar";
import { useTranslation } from "react-i18next";
import {
  useGetIbRefferListQuery,
  useCommissionHistoryQuery,
} from "../../redux/userApi";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import CommissionList from "../partials/CommissionList";

const Referral = () => {
  const [refferUserList, setRefferUserList] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState("crypto");
  const [userSelected, setUserSelected] = useState("crypto");
  const [show, setShow] = useState(false);
  const { data } = useGetIbRefferListQuery();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setRefferUserList(data);
    }
  }, [data]);

  const handleCommissionHistoryShow = (item) => {
    setUserSelected(item.userId);
    setShow(true);
  };

  const showTableHtmlReffer = () => {
    if (refferUserList.length > 0) {
      return refferUserList.map((item, index) => {
        return (
          <tr>
            <td>{index + 1}</td>
            <td>{item.user_name}</td>
            <td>{item.email ? item.email : "-"}</td>
            <td>{item.mobile_number ? item.mobile_number : "-"}</td>
            <td>{moment(item.createdAt).format("lll")}</td>
            <td>
              <button
                className="btn"
                onClick={() => handleCommissionHistoryShow(item)}
              >
                {t("View Commission")}
              </button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            No referrals
          </td>
        </tr>
      );
    }
  };

  const handleClose = () => {
    setUserSelected(null);
    setShow(false);
  };

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container ">
          <div className="row ">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="p-3  bg2 br20 ">
                <div className="row align-items-center mb-2">
                  <h5 className="nav-item m-0 col-auto">
                    {t("User Refer History")}
                  </h5>
                </div>
                <div className="table-responsive trade_scrool_table">
                  <table className="table table-striped mb-0 ">
                    <thead>
                      <tr>
                        <th>{t("Sr. No.")}</th>
                        <th>{t("Username")}</th>
                        <th>{t("Email")}</th>
                        <th>{t("Mobile Number")} </th>
                        <th>{t("Registred Date")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>{showTableHtmlReffer()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose} style={{ zIndex: "10" }}>
        <Modal.Header>
          <Modal.Title className="h6">
            {t("User Distributed Commission")}
          </Modal.Title>
          <span style={{ cursor: "pointer" }}>
            <i onClick={handleClose} className="bi bi-x-circle"></i>
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="position_tab d-flex mb-3">
              <a
                className={selectedSegment === "crypto" ? "active" : ""}
                href="!#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedSegment("crypto");
                }}
              >
                {t("Crypto")} {t("Commission")}
              </a>
              <a
                className={selectedSegment === "forex" ? "active" : ""}
                href="!#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedSegment("forex");
                }}
              >
                {t("Forex")} {t("Commission")}
              </a>
              <a
                className={selectedSegment === "equity" ? "active" : ""}
                href="!#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedSegment("equity");
                }}
              >
                {t("Equity")} {t("Commission")}
              </a>
            </div>

            <CommissionList userId={userSelected} type={selectedSegment} />
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
};

export default Referral;
