import React from "react";
import { useTranslation } from "react-i18next";
import { useCommissionHistoryQuery } from "../../redux/userApi";

const CommissionList = ({ userId, type }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useCommissionHistoryQuery({ userId, type });
  // console.log(data, "dataaa");
  if (isLoading) return;
  return (
    <>
      <div>
        <p>
          {t("Total Commission")} : ${data.totalCommission[0].totalCommission}
        </p>
      </div>
      <div className="table-responsive table trade_scrool_table">
        <table className="table">
          <thead>
            <tr>
              <th>{t("Sr. No.")}</th>
              <th>{t("Symbol")}</th>
              <th>{t("Commission")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.commissionHistory.map((item, i) => (
              <tr key={item.coin_pair}>
                <td>{i + 1}</td>
                <td>{item.coin_pair}</td>
                <td>${item.totalCommission}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CommissionList;
