import { configureStore } from "@reduxjs/toolkit";
import { myApi } from "./api";
import { myreducer } from "./reducer";
import userReducer from "./userSlice"; // Import the new user slice

export const store=configureStore({
    reducer:{
        [myApi.reducerPath]:myApi.reducer,
        [myreducer.name]:myreducer.reducer,
        user: userReducer
    },
    
    middleware: (defaultMiddleware) =>
    defaultMiddleware().concat(myApi.middleware),

})